exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-healing-js": () => import("./../../../src/pages/healing.js" /* webpackChunkName: "component---src-pages-healing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miracles-js": () => import("./../../../src/pages/miracles.js" /* webpackChunkName: "component---src-pages-miracles-js" */),
  "component---src-pages-saints-js": () => import("./../../../src/pages/saints.js" /* webpackChunkName: "component---src-pages-saints-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-eucharist-detail-js": () => import("./../../../src/templates/eucharist-detail.js" /* webpackChunkName: "component---src-templates-eucharist-detail-js" */),
  "component---src-templates-saints-detail-js": () => import("./../../../src/templates/saints-detail.js" /* webpackChunkName: "component---src-templates-saints-detail-js" */)
}

